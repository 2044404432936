import * as React from "react"
import Layout from "../../components/layout"
import AboutBammer from "../../components/about-bammer"

const AboutPage = () => {

  return (
    <div>
      <Layout>
          <AboutBammer></AboutBammer>
      </Layout>
    </div>
  )
}

export default AboutPage
import React from "react"
import {Header} from "./header"
import { StaticImage } from "gatsby-plugin-image"
import { Grid } from "@mui/material"

import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import "./layout.css"

const Layout = ({ children }) => {

  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "background2.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 4096
              height: 2300
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )

  const image = getImage(placeholderImage)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return(
    <div style={{background: '#0B2545'}}>
      <Header />
      <div style={{ display: "grid", boxShadow: 'borderBox' }}>
        <BackgroundImage
            Tag="section"
            fluid={bgImage}
            style={{gridArea: "1/1", backgroundRepeat: 'repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}
        >
            <GatsbyImage image={image} alt={"testimage"}/>
        </BackgroundImage>
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div style={{height: '100%', width: '65%', backgroundColor: '#0B2545', alignItems: 'flex-start', justifyContent: 'center', display: 'flex'}}>
            <main>{children}</main>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Layout